import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  download(headers: any, queryParams: HttpParams, apiUrl: any,responseType: 'json' | 'blob' = 'json'): Observable<any> {
    return this.http.get(apiUrl, {
      headers: headers,
      params: queryParams,
      responseType: responseType as 'json'
    }
    )
  }
}
