import { Component, NgIterable, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileService } from './file.service';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../config/GlobalConstants';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/assets/environments/environment';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})

export class ViewComponent implements OnInit {
  isVisible = false;
  public gmplogoimg!: string;
  public brandName!: string;
  public language!: string;
  public prodtype!: string;
  public mediaType!: string;
  pdfSource: any;
  fileNotFound: string;
  ackMsg: string;
  zoomSetting: number | string | undefined = 'auto';
  pdfBytes: any = [];
  videoSource: SafeResourceUrl | undefined;

  defaultMessages() {
    this.fileNotFound = GlobalConstants.fileNotFound;
    this.ackMsg = GlobalConstants.ackMsg;
  }

  productTypeMap: NgIterable<any>;
  mediaTypeMap: NgIterable<any> = [];
  brandTypeMap: NgIterable<any> = [];

  pageReset() {
    this.brandName = "";
    this.prodtype = "";
    this.language = "";
    this.mediaType ="";
    this.productTypeMap = [];
    this.mediaTypeMap = [];
    this.brandTypeMap = [];
  }

  productTypeReset() {
    this.prodtype = "";
    this.language = "";
  }
  brandTypeReset(){
    this.brandName="";
    this.language="";
    this.prodtype="";
  }
  public changeProductType(lang: string) {
    const languageMap: {[key: string]: {[key: string]: NgIterable<any>}} = {
      "English": {
        "PowerUP": GlobalConstants.productTypesPowerUpEN,
        "PowerUP+": GlobalConstants.productTypesPowerUpPlusEN,
        "General":GlobalConstants.productTypesGeneralEN
      },
      "en": {
        "PowerUP": GlobalConstants.productTypesPowerUpEN,
        "PowerUP+": GlobalConstants.productTypesPowerUpPlusEN,
        "General":GlobalConstants.productTypesGeneralEN
      },
      "French": {
        "PowerUP": GlobalConstants.productTypesPowerUpFR,
        "PowerUP+": GlobalConstants.productTypesPowerUpPlusFR,
        "General":GlobalConstants.productTypesGeneralFR
      }
    };
    
    if (this.mediaType === GlobalConstants.ACRYLICSTANDINSERT) {
      this.productTypeMap = this.isEnglish(lang) ? GlobalConstants.productTypesAcrylicStandEN 
       : this.isFrench(lang)   ? GlobalConstants.productTypesAcrylicStandFR  : [];
    } else if (this.brandName !== undefined && languageMap[lang]) {
      const brandLanguageMap = languageMap[lang][this.brandName];
      if (brandLanguageMap === undefined) {
        this.productTypeMap = this.isEnglish(lang) ? GlobalConstants.productTypesEN 
          : this.isFrench(lang) ? GlobalConstants.productTypesFR : [];
      } else {
        this.productTypeMap = brandLanguageMap;
      }
    } else {
      this.productTypeMap = [];
    }
  }

  public changeMMType(lang: string) {
  this.mediaTypeMap = GlobalConstants.mmTypeLangMap[lang];
}

public changeBrandType(mediaType: string, lang:string) {
  if (mediaType === GlobalConstants.COMMUNICATIONS) {
    if (this.isFrench(lang)){
      this.brandTypeMap = GlobalConstants.brandTypeCommunicationsFR;
    } else {
      this.brandTypeMap = GlobalConstants.brandTypeCommunicationsEN;
    }
  } else {
    const brandTypeMap: {[key: string]: NgIterable<any>} = {
      "Brochures": GlobalConstants.brandTypeBrochures,
      "Desk_Mats": GlobalConstants.brandTypeDefault,
      "Acrylic_Stand_Insert": GlobalConstants.brandTypeDefault,
      "Videos": GlobalConstants.brandTypeDefault
    };
    this.brandTypeMap = brandTypeMap[mediaType] ?? [];
  }
}

  isEnglish(lang: string){
    if(lang === "English" || lang == "en") {
      return true;
    }
    return false;
  }

  isFrench(lang: string){
    if(lang === "French") {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    if (window.location.href.includes("view")) {
      this.isVisible = true;
    }
    this.gmplogoimg = this.activateImages();
    this.brandName = "";
    this.language = "";
    this.prodtype = "";
    this.mediaType ="";
    this.changeMMType(this.translate.getDefaultLang())
    this.defaultMessages();
  }


  constructor(
    private fileDownloadService: FileService,
    private router: Router, 
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {

    translate.addLangs(['English', 'French']);
    translate.setDefaultLang('English');
  }

  async viewPdf(viewForm: NgForm) {
    this.defaultMessages();
    if (viewForm.valid) {
      let access_token = sessionStorage.getItem('access_token'),
        headers: HttpHeaders = new HttpHeaders({
            "Authorization": "bearer " + access_token,
            "Accept":this.mediaType === 'Videos' ? "application/json" : "application/pdf",
            "Content-Type": this.mediaType === 'Videos' ? "application/json" : "application/pdf",
            "idToken": sessionStorage.getItem('id_token')!
          });
      let queryParams = new HttpParams();
      queryParams = queryParams.append("mmType", this.mediaType);
      queryParams = queryParams.append("language", this.language);
      queryParams = queryParams.append("brandName", this.brandName);
      queryParams = queryParams.append("productType", this.prodtype);
      this.spinner.show();
      let baseApiUrl = window.location.href.includes(GlobalConstants.extPath) ? environment.baseUrlExt + environment.fileContentUrl : environment.baseUrlInt + environment.fileContentUrl;
      const responseType: 'json' | 'blob' = this.mediaType === 'Videos' ? 'json' : 'blob';
      this.fileDownloadService.download(headers, queryParams,baseApiUrl,responseType).subscribe({
        next: c => {
          if (this.mediaType === 'Videos') {
            this.videoSource = this.sanitizer.bypassSecurityTrustResourceUrl(c.fileName);
            var x = document.getElementById('videoDiv');
            if (x != null && x.style.display == 'none') {
                x.style.display = 'block';
            }
        } else {
          const newBlob = new Blob([c], { type: 'application/pdf' });
          var x = document.getElementById('pdfDiv');
          if (x != null && x.style.display == 'none') {
            x.style.display = 'block';
          }
          this.pdfSource = newBlob;
        }
      },
        error: error => {
          this.spinner.hide();
          if (this.mediaType === 'Videos' && error.status === 404) {
            this.videoSource = this.sanitizer.bypassSecurityTrustResourceUrl(GlobalConstants.inValidUrl);
            var x = document.getElementById('videoDiv');
            if (x != null && x.style.display == 'none') {
              x.style.display = 'block';
            }
          } else {
            var x = document.getElementById('pdfDiv');
            if (x != null) {
              x.style.display = 'none';
            }
            this.langValidation(GlobalConstants.fileNotFoundFrench, GlobalConstants.ackMsgFrench);
            this.FailureNotification(this.fileNotFound, this.ackMsg);
          }
        },
        complete: () => {
          this.spinner.hide();
          console.log('Request complete');
        }
      },
      );
    }
  }

  langValidation(errorText: string, ackMsg: string) {
    if (document.documentElement.lang == "French") {
      this.fileNotFound = errorText;
      this.ackMsg = ackMsg;
    }
  }

  FailureNotification(successText: string, ackMsg: string) {
    Swal.fire({
      text: successText,
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: ackMsg,
      confirmButtonColor: GlobalConstants.buttonColorCode,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/home'])
      }
    });
  }

  public activateImages() {
    if (document.documentElement.lang == "French") {
      this.gmplogoimg = "/assets/images/gmProtectionsLogo_French.jpg";
    } else {
      this.gmplogoimg = "/assets/images/gmProtectionsLogo.jpg";
    }
    return this.gmplogoimg;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    document.documentElement.lang = lang;
    this.activateImages();
    this.pageReset();
    this.changeMMType(lang)
    var x = document.getElementById('pdfDiv');
    x!.style.display = 'none';
  }
}